import React from "react";

import item1 from "../images/item-1.jpg";
import item2 from "../images/item-2.png";
import item3 from "../images/item-3.png";
import item4 from "../images/item-4.png";
import item5 from "../images/item-5.png";
import item6 from "../images/item-6.png";
import banner_1 from "../images/banner_1.png";
import banner_2 from "../images/banner_2.png";
import banner_3 from "../images/banner_3.png";
import banner_4 from "../images/banner_4.png";
import banner_5 from "../images/banner_5.png";
import banner_6 from "../images/banner_6.png";
import banner_7 from "../images/banner_7.png";
import banner_8 from "../images/banner_8.png";
import banner_alt from "../images/banner_alt.png";
import banner_ust_sol from "../images/banner_ust_sol.png";
import banner_ust_sag from "../images/banner_ust_sag.png";
import banner_sag from "../images/banner_sag.png";
import logo from "../images/logo.png";

function Page1() {
  return (
    <div className="sections-container row m-0 ">
      <div className="d-none d-lg-flex col-3 col-xl-2 p-0">
        <div className="section section-1 h-100">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </div>
          <div className="items">
            <a
              href="https://www.101casinoforum.com"
              className="item"
              target="_blank"
            >
              <img src={item1} alt="item" />
            </a>
            <a href="/recommended" className="item">
              <img src={item2} alt="item" />
            </a>
            <a href="/lightning-roulette" className="item">
              <img src={item3} alt="item" />
            </a>
            <a href="/immersive-roulette" className="item">
              <img src={item4} alt="item" />
            </a>
            <a href="/roulette-live" className="item">
              <img src={item5} alt="item" />
            </a>
            <a href="/auto-roulette" className="item">
              <img src={item6} alt="item" />
            </a>
          </div>

          <div className="social-icons">
            <ul>
              <li>
                <a
                  href="https://join.skype.com/invite/BMGOziPXK9me"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#0088cc"
                      d="M18 6c2.07 2.04 2.85 4.89 2.36 7.55c.41.72.64 1.56.64 2.45a5 5 0 0 1-5 5c-.89 0-1.73-.23-2.45-.64c-2.66.49-5.51-.29-7.55-2.36c-2.07-2.04-2.85-4.89-2.36-7.55C3.23 9.73 3 8.89 3 8a5 5 0 0 1 5-5c.89 0 1.73.23 2.45.64c2.66-.49 5.51.29 7.55 2.36m-5.96 11.16c2.87 0 4.3-1.38 4.3-3.24c0-1.19-.56-2.46-2.73-2.95l-1.99-.44c-.76-.17-1.62-.4-1.62-1.11c0-.72.6-1.22 1.7-1.22c2.23 0 2.02 1.53 3.13 1.53c.58 0 1.08-.34 1.08-.93c0-1.37-2.19-2.4-4.05-2.4c-2.01 0-4.16.86-4.16 3.14c0 1.1.39 2.27 2.55 2.81l2.69.68c.81.2 1.01.65 1.01 1.07c0 .68-.68 1.35-1.91 1.35c-2.41 0-2.08-1.85-3.37-1.85c-.58 0-1 .4-1 .97c0 1.11 1.33 2.59 4.37 2.59"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://t.me/+dARTLWUiY8tmNzAx" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="#00aff0"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      d="m14.5 1.5l-14 5l4 2l6-4l-4 5l6 4z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@101casinoforum?si=OmybJfcZ0y_ctEnB"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#CD201F"
                      d="m10 15l5.19-3L10 9zm11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-7 col-xl-8 p-0">
        <div className="section-2 h-100 p-5">
          <a href="/" className="d-flex d-lg-none">
            <img src={logo} alt="" className="logo" />
          </a>
          <div
            className="d-flex flex-column flex-lg-row w-100 "
            style={{ gap: "20px" }}
          >
            <div className="banner-content w-100 w-lg-50">
              <a href="https://heroi.bet/affiliates/?btag=1746280_l278777" target="blank">
                <img src={banner_ust_sol} alt="" className="h-100 w-100" />
              </a>
            </div>
            <div className="banner-content w-100 w-lg-50">
              <a href="https://icepromo.info/l/65a0f2c209ce9f62550c552a?sub_id={sub_id_1}&click_id={click_id}" target="blank">
                <img src={banner_ust_sag} alt="" className="h-100 w-100" />
              </a>
            </div>
          </div>
          <div className="w-100 grid-container">
            <div className="grid-item">
              <a href="https://stake.com/?c=LR0XzjsA" target="blank"></a>
              <img src={banner_1} alt="Item-1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="https://bit.ly/3TpIQMX" target="blank"></a>
              <img src={banner_2} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="https://heroi.bet/affiliates/?btag=1746280_l278777" target="blank"></a>
              <img src={banner_3} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="https://icepromo.info/l/65a0f2c209ce9f62550c552a?sub_id={sub_id_1}&click_id={click_id}" target="blank"></a>
              <img src={banner_4} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="#" target="blank"></a>
              <img src={banner_5} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="#" target="blank"></a>
              <img src={banner_6} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="#" target="blank"></a>
              <img src={banner_7} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
            <div className="grid-item">
              <a href="#" target="blank"></a>
              <img src={banner_8} alt="Item 1" />
              <button className=" btn fs-3 fw-bold btn-success px-5 py-2">
                Login
              </button>
            </div>
          </div>

          <div className="banner-content">
            <a href="https://stake.com/?c=LR0XzjsA" target="blank">
              <img src={banner_alt} alt="Item 1" className="h-100 w-100" />
            </a>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-flex col-2 col-xl-1 p-0 border-start">
        <div className="w-100 h-100">
          <a href="#" target="blank">
            <img
              src={banner_sag}
              alt=""
              className="h-100 w-100 img-fluid"
              style={{ objectFit: "cover" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Page1;
