import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Recommended from "./pages/Recommended";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";
import Page5 from "./pages/Page5";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recommended" element={<Recommended />} />
          <Route path="/lightning-roulette" element={<Page2 />} />
          <Route path="/immersive-roulette" element={<Page3 />} />
          <Route path="/roulette-live" element={<Page4 />} />
          <Route path="/auto-roulette" element={<Page5 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
