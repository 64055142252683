import banner from "../images/item-6.png";
import item1 from "../images/item-1.jpg";
import item2 from "../images/item-2.png";
import item3 from "../images/item-3.png";
import item4 from "../images/item-4.png";
import item5 from "../images/item-5.png";
import item6 from "../images/item-6.png";
import banner_ana_sayfa_alt from "../images/banner_ana_sayfa_alt.png";

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const api = "https://paynowolympuspredictor.online/101forumprediction";
function Section2() {
  const [games, setGames] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!games?.length) return;
    let game = games?.find((game) => "/" + game.path === currentPath);
    if (!game) {
      game = games[0];
      navigate(`/${game.path}`);
    }
    setCurrentGame(() => game);
  }, [games]);
  useEffect(() => {
    if (!currentGame) return;
    axios
      .get(`${api}/games/${currentGame.id}/predictions/`)
      .then((response) => {
        setPredictions(() => response.data.predictions);
      });
  }, [currentGame]);
  useEffect(() => {
    let ls = localStorage.getItem("games");
    if (ls) {
      ls = JSON.parse(ls);
      setGames(() => ls.games);
      if (ls.updatedAt < Date.now() - 1000 * 60 * 60 * 4) {
        return;
      }
    }
    axios.get(`${api}/games/`).then((response) => {
      setGames(() => response.data.games);
      localStorage.setItem(
        "games",
        JSON.stringify({ games: response.data.games, updatedAt: Date.now() })
      );
    });
  }, []);
  return (
    <div className="section-2 h-100 sayilar p-5">
      <h1 className="text-white fw-bold text-center">{currentGame?.title}</h1>
      <div className="items d-grid d-lg-none">
        <a
          href="https://www.101casinoforum.com"
          className="item"
          target="_blank"
        >
          <img src={item1} alt="item" />
        </a>
        <a href="/recommended" className="item">
          <img src={item2} alt="item" />
        </a>
        <a href="/lightning-roulette" className="item">
          <img src={item3} alt="item" />
        </a>
        <a href="/immersive-roulette" className="item">
          <img src={item4} alt="item" />
        </a>
        <a href="/roulette-live" className="item">
          <img src={item5} alt="item" />
        </a>
        <a href="/auto-roulette" className="item">
          <img src={item6} alt="item" />
        </a>
      </div>
      <table style={{ borderSpacing: "1px", borderCollapse: "unset" }}>
        <tbody>
          {tableObject.map((item, index) => (
            <tr key={index}>
              {item.row.map((itemsec, index2) => (
                <th
                  key={index2}
                  className={itemsec.class}
                  onClick={() => {
                    const filtered = predictions.find(
                      (prediction) => prediction.key === itemsec.value
                    );
                    if (!filtered) return;
                    // console.log(filtered, itemsec);
                    setDescription(() => filtered.value);
                  }}
                  rowSpan={itemsec.rowspan ? itemsec.rowspan : ""}
                >
                  {itemsec.value}
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="desc-card-content d-flex text-center justify-content-center align-items-center">
        <h1 className="text-white fw-bold m-0">{description}</h1>
      </div>

      <div className="banner-content">
        <a href="https://stake.com/?c=LR0XzjsA" target="blank">
          <img src={banner_ana_sayfa_alt} alt="" className="h-100 w-100" />
        </a>
      </div>
    </div>
  );
}

export default Section2;

var tableObject = [
  {
    row: [
      {
        class: "yesil",
        rowspan: "3",
        value: "0",
        description: "1 - 2 Komşu 3 veya 4",
      },
      {
        class: "kirmizi",
        value: "3",
        description: "12 - 25 Komşu 2 veya 3",
      },
      {
        class: "siyah",
        value: "6",
        description: "32 - 12 Komşu 2 veya 3",
      },
      {
        class: "kirmizi",
        value: "9",
        description: "13 - 16 Komşu 2 veya 3",
      },
      {
        class: "kirmizi",
        value: "12",
        description: "15 - 27 Komşu 2 veya 3",
      },
      {
        class: "siyah",
        value: "15",
        description: "12 - 15 Komşu 1 veya 4",
      },
      {
        class: "kirmizi",
        value: "18",
        description: "18 - 21 Komşu 2 veya 5",
      },
      {
        class: "kirmizi",
        value: "21",
        description: "23 - 26 Komşu 3 veya 6",
      },
      {
        class: "siyah",
        value: "24",
        description: "28 - 31 Komşu 4 veya 7",
      },
      {
        class: "kirmizi",
        value: "27",
        description: "33 - 36 Komşu 5 veya 8",
      },
      {
        class: "kirmizi",
        value: "30",
        description: "5 - 8 Komşu 6 veya 9",
      },
      {
        class: "siyah",
        value: "33",
        description: "10 - 13 Komşu 7 veya 10",
      },
      {
        class: "kirmizi",
        value: "36",
        description: "14 - 17 Komşu 8 veya 11",
      },
    ],
  },
  {
    row: [
      {
        class: "siyah",
        value: "2",
        description: "19 - 22 Komşu 9 veya 12",
      },
      {
        class: "kirmizi",
        value: "5",
        description: "25 - 28 Komşu 10 veya 13",
      },
      {
        class: "siyah",
        value: "8",
        description: "7 - 9 Komşu 1 veya 4",
      },
      {
        class: "siyah",
        value: "11",
        description: "16 - 18 Komşu 3 veya 5",
      },
      {
        class: "kirmizi",
        value: "14",
        description: "20 - 23 Komşu 2 veya 6",
      },
      {
        class: "siyah",
        value: "17",
        description: "27 - 29 Komşu 4 veya 7",
      },
      {
        class: "siyah",
        value: "20",
        description: "30 - 32 Komşu 5 veya 8",
      },
      {
        class: "kirmizi",
        value: "23",
        description: "34 - 36 Komşu 6 veya 9",
      },
      {
        class: "siyah",
        value: "26",
        description: "1 - 3 Komşu 7 veya 10",
      },
      {
        class: "siyah",
        value: "29",
        description: "4 - 6 Komşu 8 veya 11",
      },
      {
        class: "kirmizi",
        value: "32",
        description: "8 - 10 Komşu 9 veya 12",
      },
      {
        class: "siyah",
        value: "35",
        description: "11 - 13 Komşu 10 veya 13",
      },
    ],
  },
  {
    row: [
      {
        class: "kirmizi",
        value: "1",
        description: "2 - 7 Komşu 1 veya 4",
      },
      {
        class: "siyah",
        value: "4",
        description: "9 - 14 Komşu 2 veya 5",
      },
      {
        class: "kirmizi",
        value: "7",
        description: "16 - 21 Komşu 3 veya 6",
      },
      {
        class: "siyah",
        value: "10",
        description: "23 - 28 Komşu 4 veya 7",
      },
      {
        class: "siyah",
        value: "13",
        description: "30 - 35 Komşu 5 veya 8",
      },
      {
        class: "kirmizi",
        value: "16",
        description: "6 - 11 Komşu 1 veya 4",
      },
      {
        class: "kirmizi",
        value: "19",
        description: "13 - 18 Komşu 2 veya 5",
      },
      {
        class: "siyah",
        value: "22",
        description: "20 - 25 Komşu 3 veya 6",
      },
      {
        class: "kirmizi",
        value: "25",
        description: "27 - 32 Komşu 4 veya 7",
      },
      {
        class: "siyah",
        value: "28",
        description: "34 - 1 Komşu 5 veya 8",
      },
      {
        class: "siyah",
        value: "31",
        description: "24 - 35 Komşu 2 veya 3",
      },
      {
        class: "kirmizi",
        value: "34",
        description: "13 - 18 Komşu 2 veya 5",
      },
    ],
  },
];
